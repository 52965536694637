/**
 * Created by minimus on 12.07.2015.
 */
const fHits = [];
let ads = [];
let nIntervalID;

(function ($) {
  $(document).ready(function () {
    const options = samProOptions;
    const doStats = (options.doStats === '1');
    const jsStats = (1 === parseInt(options.jsStats, 10));
    //const mailer = (options.mailer === '1');
    const load = (options.load === '1');
    const path = options.ajaxUrl;
    const nonce = options.samProNonce;
    const data = options.clauses;
    const pageData = options.pageData;
    const rotate = (1 === parseInt(options.rotate, 10));
    const rotateInterval = parseInt(options.rotateInterval, 10) * 1000;
    const hits = [];

    function samProLoadAds() {
      $.post(path, {action: 'sam_load_ads', ads, data, page_data: pageData, sam_pro_nonce: nonce})
        .done((response) => {
          if (response.success) {
            const aAds = [];
            const aHits = [];
            $.each(response.data, (i, ad) => {
              let found = false;
              //let fFound = false;
              $('#' + ad.eid).replaceWith(ad.ad);
              aAds.push({pid: ad.pid, aid: 0, codes: ad.codes, eid: ad.cid});
              $.each(fHits, (i, val) => {
                found = ((val.aid === ad.aid) && (val.pid === ad.pid));
                return !found;
              });
              if (!found) {
                fHits.push({pid: ad.pid, aid: ad.aid});
                aHits.push({pid: ad.pid, aid: ad.aid});
              }
              if (doStats) {
                const cnt = $('#' + ad.cid);
                cnt.find('a').on('click', () => {
                  $.post(path, {
                    action: 'sam_click',
                    aid: ad.aid,
                    pid: ad.pid,
                    sam_pro_nonce: nonce
                  });
                });
                cnt.find('iframe').iframeTracker({
                  blurCallback: function () {
                    $.post(path, {
                      action: 'sam_click',
                      aid: ad.aid,
                      pid: ad.pid,
                      sam_pro_nonce: nonce
                    });
                  }
                });
              }
            });
            ads = [...aAds];
            if (doStats && jsStats && (aHits.length > 0)) {
              $.post(path, {action: 'sam_hits', hits: aHits, sam_pro_nonce: nonce});
            }
          }
        });
    }

    if (load) {
      // Ads loaded by PHP
      if (doStats) {
        $(`div.${options.place}, span.${options.place}`).each((i, el) => {
          const ids = el.id.split('_');
          const aid = parseInt(ids[1], 10);
          const pid = parseInt(ids[2], 10);
          hits.push({aid, pid});
          $(el).find('a').on('click', () => {
            $.post(path, {action: 'sam_click', aid, pid, sam_pro_nonce: nonce});
          });
          $(el).find('iframe').iframeTracker({
            blurCallback: function () {
              $.post(path, {action: 'sam_click', aid, pid, sam_pro_nonce: nonce});
            }
          });
        });

        if (jsStats && !!hits) {
          $.post(path, {action: 'sam_hits', hits, sam_pro_nonce: nonce});
        }
      }

      // Loading Ads
      ads = [];
      $(`div.${options.ad}, span.${options.ad}`).each((i, el) => {
        let codes = $(el).data('spc');
        if ('undefined' === typeof codes) codes = 0;
        const ids = el.id.split('_');
        const id = ids[1];
        const pid = ids[2];

        ads.push({pid, aid: id, codes, eid: el.id});
      });

      if (ads.length > 0) {
        samProLoadAds(ads);
        if (rotate) nIntervalID = setInterval(samProLoadAds, rotateInterval);
      }
    }
    else {
      if (doStats) {
        $(`div.${options.container}, span.${options.container}`).each((i, el) => {
          const ids = el.id.split('_');
          const aid = ids[1];
          const pid = ids[2];
          hits.push({aid, pid});
          $(el).find('a').on('click', () => {
            $.post(path, {action: 'sam_click', aid, pid, sam_pro_nonce: nonce});
          });
          $(el).find('iframe').iframeTracker({
            blurCallback: function () {
              $.post(path, {action: 'sam_click', aid, pid, sam_pro_nonce: nonce});
            }
          });
        });

        if (jsStats && !!hits) {
          $.post(path, {action: 'sam_hits', hits, sam_pro_nonce: nonce});
        }
      }
    }
  });
})(jQuery);